<template>
  <div v-if="success">
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <table style="width: 300px" aria-describedby="filter">
            <tr>
              <th id="year_head">Год</th>
              <th id="year_month">Месяц</th>
            </tr>
            <tr>
              <td>
                <div class="control">
                  <div class="select">
                    <label>
                      <select v-model="year" @change="getData()">
                        <option v-for="(y,k) in years" :value="k" v-text="y" v-bind:key="k" />
                      </select>
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <div class="control">
                  <div class="select">
                    <label>
                      <select v-model="month" @change="getData()">
                        <option v-for="(m,k) in months" :value="k" v-text="m" v-bind:key="k" />
                      </select>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <table class="ngrok" id="tst" aria-describedby="total_salary" v-if="ngrok.Total_salary_table && ngrok.Total_salary_table.length">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr>
              <th scope="col">{{th.a}}</th>
              <th scope="col">{{th.b}}</th>
              <th scope="col">{{th.c}}</th>
            </tr>
            <tr v-for="(tst, k) in ngrok.Total_salary_table" v-bind:key="tst.k">
              <td v-text="tst.friendly_item"></td>
              <td>
                <money class="text-right" v-bind="money" v-model="tst.value" />
              </td>
              <td v-text="tst.comment"></td>
            </tr>
          </table>
          <table class="ngrok" id="tit" aria-describedby="total_income" v-if="ngrok.Total_income_table && ngrok.Total_income_table.length">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr v-for="(tit, k) in ngrok.Total_income_table" v-bind:key="tit.k">
              <td v-text="tit.friendly_item"></td>
              <td><money class="text-right" v-bind="money" v-model="tit.value" /></td>
              <td v-text="tit.comment"></td>
            </tr>
          </table>
          <table class="ngrok" id="pt" aria-describedby="payouts" v-if="ngrok.Payouts_table && ngrok.Payouts_table.length">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr v-for="(pt, k) in ngrok.Payouts_table" v-bind:key="pt.k">
              <td v-text="pt.friendly_item"></td>
              <td><money class="text-right" v-bind="money" v-model="pt.value" /></td>
              <td></td>
            </tr>
          </table>
          <table class="ngrok-dt" id="dt" aria-describedby="debts" v-if="ngrok.Debts_table && ngrok.Debts_table.length">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr v-for="(dt, k) in ngrok.Debts_table" v-bind:key="k">
              <td v-text="dt.friendly_item"></td>
              <td><money class="text-right" v-bind="money" v-model="dt.value" /></td>
              <td></td>
            </tr>
          </table>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ROLE_HEAD_DOCTOR,
  ROLE_DOCTOR_EMPLOYEE
} from '../../models/AuthUser'

import api from '../../utils/api'
import moment from 'moment'

export default {
  props: {
    month: {
      type: String,
      default: moment().endOf('month').format('-MM-DD')
    },
    year: {
      type: String,
      default: moment().format('YYYY')
    },
    login: {
      type: String
    },
    date: {
      type: String,
      default: moment().endOf('month').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      money: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      years: {
        '2016': '2016',
        '2017': '2017',
        '2018': '2018',
        '2019': '2019',
        '2020': '2020',
        '2021': '2021'
      },
      months: {
        '-01-31': 'Январь',
        '-02-28': 'Февраль',
        '-03-31': 'Март',
        '-04-30': 'Апрель',
        '-05-31': 'Май',
        '-06-30': 'Июнь',
        '-07-31': 'Июль',
        '-08-31': 'Август',
        '-09-30': 'Сентябрь',
        '-10-31': 'Октябрь',
        '-11-30': 'Ноябрь',
        '-12-31': 'Декабрь'
      },
      ngrok: {
        'Debts_table': null,
        'Payouts_table': null,
        'Total_income_table': null,
        'Total_salary_table': null
      },
      th: {
        'a': 'Начисление',
        'b': 'Сумма, руб',
        'c': 'Примечание'
      }
    }
  },
  mounted () {
    this.getData(this.date)
  },
  methods: {
    success () {
      const roles = this.$store.state.app.currentUser.roles
      return roles.indexOf(ROLE_HEAD_DOCTOR) > -1 || roles.indexOf(ROLE_DOCTOR_EMPLOYEE) > -1;

    },
    getData (date) {
      const headers = {
        'Content-Type': 'application/json'
      }

      let currentDate = this.year + this.month
      if (date) {
        currentDate = date
      }

      api.post('/api/v1/salary/paysheet', {
        'login': this.$store.state.app.currentUser.username,
        'date': currentDate
      }, {headers, withCredentials: false})
        .then(response => (this.ngrok = response.body))
        .catch(error => console.log(error))
    }
  }
}
</script>

<style lang="scss">
table.ngrok {
  width: 100%;
  margin-bottom: 50px;
  th {
    border-bottom: 2px solid #333;
    padding: 2px;
    background-color: #fafafa;
  }
  th:nth-child(2) {
    width: 150px;
    text-align: right;
    padding-right: 40px;
  }

  td {
    padding: 5px 0 5px 0;
  }

  tr:last-child {
    font-weight: bold;
    border-top: 1px solid #333;
  }

  td:nth-child(1) {
    width: 500px;
  }

  td:nth-child(2) {
    width: 150px;
    text-align: right;
    padding-right: 40px;
  }
}

.ngrok-dt {
  color: #888;

  td:nth-child(1) {
    width: 500px;
  }

  td:nth-child(2) {
    width: 150px;
  }
}
</style>
